.blog-detail {
  margin: 0 auto;
}

.blog-detail-title {
  font-size: 36px;
}

.blog-detail-date {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

.blog-detail-text-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.blog-detail-text-paragraph {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 20px;
}
