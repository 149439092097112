.title {
  font-size: 36px;
  margin: 0;
}

.price {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  margin-top: 15px;
  margin-bottom: 15px;
}

.price__value {
  font-weight: bold;
}

.price__change {
  font-size: 1rem;
  color: green;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.info-section {
  flex-basis: 48%;
  margin-bottom: 2rem;
}

.section-title {
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 1rem;
}

.info-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.info-item {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  text-align: right;
  padding-bottom: 5px;
  border-bottom: 1px dashed black;
}

.info-key {
  float: left;
}
