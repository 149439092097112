
.submenu-item:hover .submenu {
  display: block;
}

.submenu {
  display: none;
  position: absolute;
  top: 0;
  left: 100%; /* Move the submenu to the right side of the main menu item */
  list-style-type: none;
  padding: 0;
  box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.1);
  background-color: #ff5e14 !important;
  transition: all 0.8s ease;
  width: 220px;
}

.submenu > li {
  width: 100%;
}

.submenu a {
  padding: 10px;
  font-size: 15px;
}

.submenu li:hover a {
  background-color: #f25a13 !important;
}