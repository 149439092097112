/* PdfViewer.css */
.pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-pdf__Page__canvas {
  margin: auto;
}

.react-pdf__Page__canvas {
  max-width: 1188px !important;
  max-height: 1635px !important;
  @media only screen and (max-width: 767px) {
    width: 100% !important;
    height: auto !important;
  }
}

.page-container {
  margin: 1rem;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.pagination li {
  margin: 0 0.5rem;
}

.pagination button {
  background-color: white;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination button:hover {
  background-color: #f1f1f1;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
