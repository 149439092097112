.history-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
}

.history-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

.history-image {
  width: 40%;
  max-width: 400px;
  &-left {
    margin-left: 3rem;
  }
  &-right {
    margin-right: 3rem;
  }
}

.history-image img {
  width: 350px;
  height: 350px;
  border-radius: 5px;
}

.history-content {
  width: 60%;
}

.history-year {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.history-text {
  font-size: 1rem;
  line-height: 1.5;
}